<template>
<section class="service">
   <div class="banner">
      <img :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   </div>
   <section class="main_contain">
      <figure>
         <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$t('PC.Service.gflg0l')" name="first">
               <figure class="tab_1">
                  <div class="con_1">
                     <p class="c1_p1">{{ $t("PC.Service.f27zgm") }}</p>
                     <p class="c1_p2">{{ $t("PC.Service.eweb7a") }}</p>
                     <p class="c1_t1">{{ $t("PC.Service.0gdzei") }}</p>
                     <div class="pici_box">
                        <div
                           class="pici"
                           v-for="(val, index) in iconsymbol"
                           :key="index">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use :xlink:href="val.icon"></use>
                           </svg>
                           <p>{{ val.text }}</p>
                        </div>
                     </div>
                  </div>
                  <div class="con_2">
                     <p class="c2_p1">{{ $t("PC.Service.jdmfns") }}</p>
                     <p class="c2_p2">{{ $t("PC.Service.4nimxt") }}</p>
                     <div class="top_con">
                        <div class="box">
                           <img src="../../assets/zh/images/shiye_img1@2x.jpg" alt="" />
                           <div>
                              <p>{{ $t("PC.Service.d7yvpr") }}</p>
                              <p>{{ $t("PC.Service.v47kbf") }}</p>
                           </div>
                        </div>
                        <div class="box">
                           <img src="../../assets/zh/images/shiye_img2@2x.jpg" alt="" />
                           <div>
                              <p>{{ $t("PC.Service.9n5odf") }}</p>
                              <p>{{ $t("PC.Service.y6e7ep") }}</p>
                           </div>
                        </div>
                        <div class="box">
                           <img src="../../assets/zh/images/shiye_img3@2x.jpg" alt="" />
                           <div>
                              <p>{{ $t("PC.Service.pimt4e") }}</p>
                              <p>{{ $t("PC.Service.lcbhgj") }}</p>
                           </div>
                        </div>
                     </div>
                     <div class="bot_con">
                        <img src="../../assets/zh/images/shiye_img4@2x.jpg" alt="" />
                        <div>
                           <p>{{ $t("PC.Service.5qjn0r") }}</p>
                           <p>{{ $t("PC.Service.caba4v") }}</p>
                        </div>
                     </div>
                     <div class="last_p" v-html="$t('PC.Service.hns1nc')"></div>
                  </div>
                  <div class="con_3">
                     <p>{{ $t("PC.Service.1b8ynk") }}</p>
                     <div class="c3_contain top">
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_bangong"></use>
                           </svg>
                           <p>{{ $t("PC.Service.ieh5e6") }}</p>
                        </div>
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_xiangmu"></use>
                           </svg>
                           <p>{{ $t("PC.Service.r7wdip") }}</p>
                        </div>
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_zijin1"></use>
                           </svg>
                           <p v-html="$t('PC.Service.fxqbpg')"></p>
                        </div>
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_caiwu"></use>
                           </svg>
                           <p>{{ $t("PC.Service.i6mygh") }}</p>
                        </div>
                     </div>
                     <div class="c3_contain bot">
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_hehuoren"></use>
                           </svg>
                           <p>{{ $t("PC.Service.smk038") }}</p>
                        </div>
                        <div class="c3_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_shichang"></use>
                           </svg>
                           <p>{{ $t("PC.Service.6vxpr9") }}</p>
                        </div>
                        <div class="c3_box last">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_shouru"></use>
                           </svg>
                           <p>{{ $t("PC.Service.oykasb") }}</p>
                        </div>
                     </div>
                  </div>
                  <div class="con_4">
                     <figure class="fg2">
                        <figcaption>{{ $t("PC.Service.fvovfn") }}</figcaption>
                        <article>
                           <div>
                              <img src="../../assets/zh/images/shiye_img6@2x.jpg" alt="" />
                              <i>{{ $t("PC.Service.c28i94") }}</i>
                           </div>
                           <div>
                              <img src="../../assets/zh/images/shiye_img7@2x.jpg" alt="" />
                              <i>{{ $t("PC.Service.53n5e8") }}</i>
                           </div>
                           <div>
                              <img src="../../assets/zh/images/shiye_img8@2x(1).jpg" alt="" />
                              <i>{{ $t("PC.Service.58j3hr") }}</i>
                           </div>
                           <div>
                              <img src="../../assets/zh/images/shiye_img8@2x.jpg" alt="" />
                              <i>{{ $t("PC.Service.eyki86") }}</i>
                           </div>
                           <div>
                              <img src="../../assets/zh/images/shiye_img10@2x.jpg" alt="" />
                              <i>{{ $t("PC.Service.klq0pj") }}</i>
                           </div>
                        </article>
                     </figure>
                  </div>
                  <div class="con_5">
                     <p>{{ $t("PC.Service.zypmfd") }}</p>
                     <div class="c5_contain">
                        <div class="c5_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_chuangye"></use>
                           </svg>
                           <p>{{ $t("PC.Service.bj1etd") }}</p>
                           <p>{{ $t("PC.Service.p4ed3m") }}</p>
                        </div>
                        <div class="c5_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_xueli"></use>
                           </svg>
                           <p>{{ $t("PC.Service.l93wdq") }}</p>
                           <p class="spacial_p">{{ $t("PC.Service.11bzyr") }}</p>
                        </div>
                        <div class="c5_box">
                           <svg class="iconsymbol" aria-hidden="true">
                              <use xlink:href="#iconshiye_suzhi"></use>
                           </svg>
                           <p>{{ $t("PC.Service.57rt9p") }}</p>
                           <p>{{ $t("PC.Service.p5od6g") }}</p>
                        </div>
                     </div>
                  </div>
                  <div class="con_6">
                     <figcaption>{{ $t("PC.Service.txdblr") }}</figcaption>
                     <div>
                        <a href="https://map.baidu.com/search/%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC/@13220466.035,3750660.43,19z?querytype=s&da_src=shareurl&wd=%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC&c=1&src=0&pn=0&sug=0&l=5&b=(2853289.960466571,1077844.7497895127;18581929.96046657,8753748.749789514)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1">
                           <img src="../../assets/zh/images/aboutus_img2.jpg" alt="" />
                        </a>
                        <article>
                           <p>{{ $t("PC.Service.bqpct5") }}</p>
                           <p>{{ $t("PC.Service.xz6nnu") }}</p>
                           <p>{{ $t("PC.Service.0ldt5w") }}</p>
                        </article>
                     </div>
                  </div>
               </figure>
            </el-tab-pane>
            <el-tab-pane :label="$t('PC.Service.7nqh23')" name="second">
               <figure class="tab_2">
                  <div>
                     <p>{{ $t("PC.Service.4mpt1c") }}</p>
                     <span>{{ $t("PC.Service.fb5rhr") }}</span>
                  </div>
                  <div>
                     <p>{{ $t("PC.Service.kixe3o") }}</p>
                     <span>{{ $t("PC.Service.ijf1pe") }}</span>
                  </div>
                  <div>
                     <p>{{ $t("PC.Service.httj06") }}</p>
                     <span>{{ $t("PC.Service.ia6gkv") }}</span>
                  </div>
                  <div>
                     <p>{{ $t("PC.Service.mye5uo") }}</p>
                     <span>{{ $t("PC.Service.u4446i") }}</span>
                  </div>
                  <div>
                     <p>{{ $t("PC.Service.fk9uwq") }}</p>
                     <span>{{ $t("PC.Service.ioq2jl") }}</span>
                  </div>
               </figure>
            </el-tab-pane>
         </el-tabs>
      </figure>

      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "./components/FooterC";

export default {
   components: {
      FooterC
   },
   data() {
      return {
         banner: require('../../assets/zh/images/fuwu_banner@2x.jpg'),
         banner_en: require('../../assets/en/images/fuwu_banner@2x.jpg'),
         iconsymbol: [{
               icon: "#iconshiye_jishu",
               text: this.$t("PC.Service.98nu5s")
            },
            {
               icon: "#iconshiye_ziyuan",
               text: this.$t("PC.Service.8btb2o")
            },
            {
               icon: "#iconshiye_zijin",
               text: this.$t("PC.Service.05tv89")
            },
            {
               icon: "#iconshiye_qvdao",
               text: this.$t("PC.Service.913u8y")
            },
            {
               icon: "#iconshiye_shangji",
               text: this.$t("PC.Service.ay404s")
            }
         ],
         activeName: "first"
      };
   },
   methods: {
      handleClick(tab, event) {
         console.log(this.activeName);
         console.log(tab, event);
      }
   }
};
</script>

<style lang="scss" scoped>
.service {
   margin-top: 70Px;
   background-color: #fff;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 440Px;
      position: relative;
      overflow: hidden;

      img {
         display: block;
         position: absolute;
         left: 50%;
         transform: translate(-50%, 0);
         width: 1920Px;
         height: 440Px;
         margin: 0 auto;
      }
   }

   .main_contain {
      position: absolute;
      background-color: #fff;
      top: 510Px;
      width: 100%;

      figure.tab_1 {
         background-color: #fff;

         .con_1 {
            background-color: #fff;
            height: 556Px;

            &>p {
               color: #333;
               text-align: center;
               font-size: 32Px;
               line-height: 45Px;
            }

            .c1_p1 {
               font-size: 32Px;
               color: #333;
               padding-top: 40Px;
            }

            .c1_p2 {
               font-size: 22Px;
               line-height: 58Px;
               padding: 24Px 0 72Px;
            }

            .c1_t1 {
               padding-bottom: 48Px;
            }

            .pici_box {
               display: flex;
               justify-content: center;

               .pici {
                  padding: 0 54Px;
                  text-align: center;

                  svg.iconsymbol {
                     width: 106Px;
                     height: 106Px;
                     display: inline-block;
                  }

                  p {
                     padding-top: 8Px;
                     text-align: center;
                     font-size: 22Px;
                     line-height: 30Px;
                  }
               }
            }
         }

         .con_2 {
            background-color: #F5F6F9;

            p {
               text-align: center;
               color: #333333;
            }

            .c2_p1 {
               font-size: 32Px;
               color: #212121;
               line-height: 45Px;
               padding: 48Px 0 39Px;
            }

            .c2_p2 {
               font-size: 22Px;
               color: #212121;
               line-height: 30Px;
               padding-bottom: 32Px;
            }

            .top_con {
               display: flex;
               justify-content: center;

               .box {
                  width: 304Px;
                  margin: 0 12Px;

                  img {
                     width: 304Px;
                     height: 193Px;
                  }

                  div {
                     width: 304Px;
                     height: 154Px;
                     background-color: #fff;
                     padding: 14Px 22Px 22Px;
                     box-sizing: border-box;

                     p:nth-of-type(1) {
                        font-size: 24Px;
                        line-height: 33Px;
                        text-align: left;
                        padding-bottom: 8Px;
                        word-break: keep-all !important;
                     }

                     p:nth-of-type(2) {
                        text-align: left;
                        font-size: 16Px;
                        font-family:   PingFangSC-Regular, PingFang SC;
                        color: #666;
                        line-height: 22Px;
                     }
                  }
               }
            }

            .bot_con {
               display: flex;
               width: 960Px;
               margin: 32Px auto;
               // padding-top: 32Px;

               img {
                  width: 480Px;
                  height: 304Px;
               }

               div {
                  background-image: url("../../assets/zh/images/shiye_img5@2x.jpg");
                  background-repeat: no-repeat;
                  background-size: contain;

                  p:nth-of-type(1) {
                     display: inline-block;
                     font-size: 24Px;
                     color: #333;
                     line-height: 33Px;
                     padding: 76Px 0 0 32Px;
                  }

                  p:nth-of-type(2) {
                     text-align: left;
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666;
                     line-height: 30Px;
                     padding: 20Px 32Px 0;
                  }
               }
            }

            .last_p {
               text-align: center;
               font-size: 22Px;
               line-height: 58Px;
               padding: 47Px 0 52Px;
            }
         }

         .con_3 {
            text-align: center;
            background-color: #fff;

            &>p {
               font-size: 32Px;
               line-height: 45Px;
               padding-top: 72Px;
               padding-bottom: 32Px;
            }

            div.c3_box {
               margin: 0 7Px;
               color: #000;
               width: 230Px;
               height: auto;
               background-color: rgba($color: #f5f6f9, $alpha: 0.53);
               text-align: left;
               box-sizing: border-box;
               padding: 24Px 18Px 24Px 18Px;

               svg.iconsymbol {
                  width: 38Px;
                  height: 38Px;
                  font-size: 11Px;
                  padding-bottom: 12Px;
                  box-sizing: content-box;
               }

               p {
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  color: #666;
                  line-height: 22Px;
                  padding-top: 16Px;
                  border-top: 2Px solid #0044d4;

                  i {
                     color: #0044d0;
                  }
               }
            }

            .c3_contain {
               display: flex;
               justify-content: center;
               padding-bottom: 15Px;

               .last {
                  width: 472Px;
                  height: auto;
               }
            }
         }

         .con_4 {
            figure.fg2 {
               width: 100%;
               box-sizing: border-box;
               padding-bottom: 63Px;
               background-color: #fff;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;

               figcaption {
                  font-size: 32Px;
                  font-weight: 500;
                  color: #333333;
                  line-height: 45Px;
                  padding: 63Px 0 32Px;
               }

               article {
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  min-width: 1903Px;

                  div {
                     width: 346Px;
                     height: 255Px;
                     margin: 0 15Px;
                     background-color: rosybrown;
                     position: relative;
                     margin-bottom: 20Px;

                     img {
                        width: 346Px;
                        height: 255Px;
                     }

                     i {
                        line-height: 55Px;
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 55Px;
                        background: rgba($color: #000, $alpha: 0.58);
                        font-size: 18Px;
                        font-family:   PingFangSC-Medium, PingFang SC;
                        color: #ffffff;
                     }
                  }
               }

               @media only screen and (max-width: 1540Px) {
                  article {
                     width: 1160Px;
                     min-width: 960Px;
                     margin: 0 auto;
                  }
               }
            }
         }

         .con_5 {
            text-align: center;
            background-color: #fff;

            &>p {
               font-size: 32Px;
               line-height: 45Px;
               padding-bottom: 48Px;
            }

            .c5_contain {
               display: flex;
               justify-content: center;

               .c5_box {
                  width: 257Px;
                  margin: 0 53Px;

                  svg {
                     width: 122Px;
                     height: 122Px;
                  }

                  p:nth-of-type(1) {
                     font-size: 22Px;
                     line-height: 28Px;
                     padding: 20Px 0 12Px;
                     box-sizing: content-box;
                  }

                  p:nth-of-type(2) {
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666666;
                     line-height: 22Px;
                  }
               }
            }
         }

         .con_6 {
            background: #fff;
            width: 100%;
            height: 625Px;

            figcaption {
               height: 45Px;
               font-size: 32Px;
               font-weight: 600;
               color: #3a3a3a;
               line-height: 45Px;
               margin: 70Px 0 40Px;
               text-align: center;
            }

            div {
               display: flex;
               justify-content: center;

               a {
                  &:hover {
                     cursor: pointer;
                  }
               }

               article {
                  width: 480Px;
                  background: #282a35;
                  padding: 105Px 0 0 50Px;

                  p {
                     height: auto;
                     font-size: 16Px;
                     color: #ffffff;
                     line-height: 22Px;
                     margin: 15Px 0;
                     padding-right: 45Px;
                  }
               }
            }
         }
      }

      figure.tab_2 {
         width: 960Px;
         padding: 0 calc((100% - 960Px) / 2);
         padding-top: 72Px;
         padding-bottom: 100Px;
         box-sizing: content-box;
         background-color: #fff;

         &>div {
            height: 400Px;
            background: #f5f9fc;
            margin: 35Px 0;
            background-image: url("../../assets/zh/images/fuwu_beijing.jpg");
            overflow: hidden;

            p {
               text-align: center;
               height: 33Px;
               font-size: 24Px;
               font-weight: 600;
               color: #262626;
               line-height: 33Px;
               margin: 106Px 0 62Px;
            }

            span {
               display: block;
               margin: 0 auto;
               width: 640Px;
               font-size: 16Px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #666666;
               line-height: 30Px;
            }
         }
      }
   }
}
</style>
